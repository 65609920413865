export const DEFAULT_POST_COLORS = {
    color: '#000000',
    background: '#ffcd8be6',
    contentColor: '#000000',
};
export const DEFAULT_BUTTON_COLORS = {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    borderColor: '#000000',
};
export const DEFAULT_TEXT_COLORS = {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    borderColor: '#000000',
};
